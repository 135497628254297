import React, { useState, useEffect } from 'react';
import LogoBeta from '../svg/logo.svg'
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function MailingList() {

    const initialValues = {
        email: '',
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('')
    });

    const [mailingListState, setMailingListState] = useState("prompt");

    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
      }), [];

    function onSubmit({ email }, { setSubmitting, resetForm }) {

        if (email == "") {
            setSubmitting(false);
            return;
        }

        console.log(`${process.env.GATSBY_API_URL}/mailing-list`);

        axios({
            method: 'post',
            url: `${process.env.GATSBY_API_URL}/mailing-list`,
            data: {
                email: email
            }
        }).then(res => {
            setTimeout(() => setMailingListState('added'), 1000); /* remove timeout at some point */
            setTimeout(() => setSubmitting(false), 1000);
            setTimeout(() => resetForm(), 1000);
        }).catch(error => {
            setTimeout(() => setMailingListState('exists'), 1000);  /* remove timeout at some point */
            setTimeout(() => setSubmitting(false), 1000);
            setTimeout(() => setMailingListState("prompt"), 4000);
        });
    }

    return (
        <section id="beta" className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
            <div className="h-24 ml-2 w-full">
                <img className="mx-auto w-1/2 lg:w-1/4" src={LogoBeta} />
            </div>

            {mailingListState == 'prompt' &&
                <div className="h-32">
                    <p className="mb-1 pt-2 lg:pt-2 text-gray-600"><b>Closed Beta</b></p>
                    <p className="pt-4 text-sm md:text-2xl font-light">
                        Submit your email below to request access to Maketsheets.
                    </p>
                </div>}
            {mailingListState == 'added' &&
                <div className="h-32 pt-24 text-base md:text-2xl font-bold">
                    Thank you for signing up for the beta!
                    <p className="text-sm md:text-lg pt-4 font-light">
                        A confirmation email has been sent to your inbox!
                    </p>
                </div>}
            {mailingListState == 'exists' &&
                <div className="h-32 pt-12 text-red-700 text-sm md:text-2xl font-light">
                    Sorry, that email has already been added.
                </div>
            }

            {mailingListState != 'added' ?
                <div className="h-40">
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                        {({ errors, touched, isSubmitting }) => (
                            <Form>
                                <div className="pt-12">
                                    <Field name="email" type="text" placeholder="Your email address" className={'form-control mx-auto w-2/3 lg:w-1/3 px-4 py-4  bg-gray-100 shadow-inner rounded-md border border-gray-400 focus:outline-none' + (errors.email && touched.email ? ' bg-blue' : '')} />
                                    <ErrorMessage name="email" component="div" className="flex text-sm mx-auto w-2/3 lg:w-1/3 px-2 py-2 text-left text-red-600" />
                                </div>

                                <button type="submit" disabled={isSubmitting} className="mt-5 h-12 bg-primary hover:bg-grey-900 text-white w-2/3 lg:w-1/3 text-base py-2 px-4 font-semibold hover:bg-primary-lighter focus:outline-none focus:shadow-outline h-10">
                                    {isSubmitting ? <div className="mx-auto loader rounded-full border-2 border-t-2 border-transparent h-6 w-6" /> : <span>Request Access</span>}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
                :
                <div className="h-40" >
                </div>
            }
        </section>
    );

}
export { MailingList };
