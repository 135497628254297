import React, { useState } from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import { MailingList } from '../components/MailingList';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import ItemCard from '../components/ItemCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import learnMoreData from '../data/learnMoreData';
import Reports from '../svg/reports.svg';
import Paperwork from '../svg/paperwork.svg';
import SheetsDemo from '../media/sheets_demo.png';
import DataSource from '../svg/flows2.svg'
import Free from '../svg/free.svg'
import Standard from '../svg/standard.svg'
import Enterprise from '../svg/enterprise.svg'
import Reuse from '../svg/icons/reuse.svg'
import Metrics from '../svg/icons/metrics.svg'
import Realtime from '../svg/icons/realtime.svg'
import Search from '../svg/icons/search.svg'
import Integration from '../svg/icons/integration.svg'
import Helmet from 'react-helmet'
import Data from '../svg/icons/data.svg'
import { graphql } from 'gatsby';
import Demo from '../media/final.gif'
import TextLoop from "react-text-loop";
import { SearchMetric } from '../components/SearchMetric';

export const query = graphql`
{
  site {
      siteMetadata {
        description
        image
        keywords
        title
        twitterUsername
        url
      }
    }  
}
`;

const Index = ({data}) => {
  const DataSourceContainer = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "76%"
  }
  
  const FunctionContainer = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    fontSize: "30px"
  }
  
  const ButtonInsideInput = {
    marginLeft: "-14.7rem",
  }
  
  const SpreadsheetBorder = {
    border: "4px solid green",
  }
  const Values = {
    fontSize: "20px"
  }

  return (
    <Layout>
      <Helmet>
        <meta name="description" content={data.site.siteMetadata.description} />
        <meta name="keywords" content={data.site.siteMetadata.keywords} />
        <meta name="title" content={data.site.siteMetadata.title} />
        <meta name="og:title" content={data.site.siteMetadata.title} />
        <meta name="og:description" content={data.site.siteMetadata.description} />
        <meta name="og:type" content="website" />
        <meta name="og:locale" content="en_US" />
        <meta name="og:url" content={data.site.siteMetadata.url} />
        <meta name="google-site-verification" content="O_MxoN_YYokojChZkTZX-4XG271IToTET-5fTg5HDV4" />
        <link rel="canonical" href={data.site.siteMetadata.url} />
      </Helmet>
      <section id="home" className="pt-20 md:pt-40">
        <div className="container h-full mx-auto px-1 sm:px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Follow the market
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Financial data at your fingertips. <br /> Enrich your spreadsheets with live market data.
              {/* Access the latest stock metrics within your spreadsheets. */}
            </p>
            <p className="mt-8 md:mt-12">
              <AnchorLink to="/#beta">
                <Button className="focus:outline-none" size="lg">Get Started</Button>
              </AnchorLink>
            </p>
            <p className="mt-4 text-gray-600">Start for free, upgrade when you're ready.</p>
          </div>
          <div className="mt-8 mx-auto pl-0 md:pl-10 lg:w-1/2">
            <img src={Demo} />
          </div>
        </div>
      </section>
      <section id="howItWorks" className="pt-24 lg:pt-48">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">How it works</h2>
        </div>
        <div className="w-full mt-10 mb-16">
          <div className="">

            <div className="lg:hidden lg:px-24 text-center py-1">
              <div className="mx-auto text-lg lg:text-xl">
                <span className="bg-gray-200 px-2 py-1">= mkt(<span className="text-red-500">ticker</span>, <span className="text-green-600 ml-1">metric</span>, <span className="text-blue-600 ml-1">period</span>)</span>
              </div>
            </div>

            <p className="lg:hidden mt-12 text-left px-10 md:text-xl xl:text-2xl font-light leading-relaxed">
              Marketsheets brings the latest financial data for thousands of companies directly into your spreadsheets. With one formula you can incorporate live metrics into your spreadsheets.
            </p>

            <p className="hidden lg:block mt-12 text-center md:text-xl xl:text-2xl font-light leading-relaxed">
              Marketsheets brings the latest financial data for thousands of companies directly into your spreadsheets.
              <br />With one formula <span className="bg-gray-200 px-2 py-1">= mkt(<span className="text-red-500">ticker</span>, <span className="text-green-600 ml-1">metric</span>, <span className="text-blue-600 ml-1">period</span>)</span> you can incorporate live metrics into your spreadsheets.
            </p>
          </div>
          <div className="px-4 md:px-20">
            <img className="mx-auto mt-16 shadow-2xl" src={SheetsDemo} />
          </div>
        </div>
        <SplitSection
          id="services"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl text-center lg:text-left font-semibold leading-tight">Flexible Access</h3>
              <p className="mt-8 text-xl text-center lg:text-left font-light leading-relaxed">
                Company financials available via Excel, Google Sheets or on our web app.
              </p>
            </div>
          }
          secondarySlot={
            <div >
              <img style={DataSourceContainer} src={DataSource} />
            </div>
          }
        />

        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-24 xl:pl-32 w-full">
              <h3 className="text-3xl font-semibold text-center lg:text-left leading-tight">
                Choose from thousands of metrics
              </h3>

              <div className="text-center hidden sm:block lg:hidden my-12">
                <div className="text-xl xs:text-xl xl:text-3xl">
                  <b><i>fx</i></b> = mkt("AAPL",
                  <b>
                    <TextLoop interval={2500} springConfig={{ stiffness: 120, damping: 15 }}>
                      <span>"marketcap", "q2-14"</span>
                      <span>"ebidta", "q4-18"</span>
                      <span>"debt", "ytd"</span>
                      <span>"currentratio", "fy-19"</span>
                      <span>"legal_name"</span>
                      <span>"capex"</span>
                      <span>"pricetorevenue"</span>
                    </TextLoop>
                  </b>
                )
                <br />
                  <br />
                </div>
                <div className="text-xl lg:text-2xl xl:text-3xl">
                  <b style={SpreadsheetBorder}>
                    <TextLoop style={Values} interval={2500} springConfig={{ stiffness: 120, damping: 15 }}>
                      <span>$ 1,324,182.26</span>
                      <span>$ 78,153</span>
                      <span>$ 109,507</span>
                      <span>1.5046</span>
                      <span>Apple Inc.</span>
                      <span>$ 10,759</span>
                      <span>5.8125</span>
                    </TextLoop>
                  </b>
                </div>
              </div>

              <p className="mt-8 text-xl font-light text-center lg:text-left leading-relaxed">
                Market data is constantly changing. With one simple formula your spreadsheets come alive with <b>real-time financial data</b> injected directly into your valuation models, performance trackers, or other workflows.
              </p>
              <div className="lg:block text-center lg:text-left">
                <AnchorLink className="" to="/metrics">
                  <button className="mt-6 bg-white mx-auto hover:bg-gray-100 text-gray-800 py-2 font-semibold px-4 border border-gray-400 rounded shadow">
                    Explore metrics
                </button>
                </AnchorLink>
              </div>
            </div>

          }
          secondarySlot={
            <div className="hidden lg:block">
              <div className="text-sm sm:text-2xl xl:text-3xl">
                <b><i>fx</i></b> = mkt("AAPL",
                <b>
                  <TextLoop interval={2500} springConfig={{ stiffness: 120, damping: 15 }}>
                    <span>"marketcap", "q2-14"</span>
                    <span>"ebidta", "q4-18"</span>
                    <span>"debt", "ytd"</span>
                    <span>"currentratio", "fy-19"</span>
                    <span>"legal_name"</span>
                    <span>"capex"</span>
                    <span>"pricetorevenue"</span>
                  </TextLoop>
                </b>
              )
              <br />
                <br />
              </div>
              <div className="text-sm sm:text-2xl xl:text-3xl">
                <b style={SpreadsheetBorder}>
                  <TextLoop style={Values} interval={2500} springConfig={{ stiffness: 120, damping: 15 }}>
                    <span>$ 1,324,182.26</span>
                    <span>$ 78,153</span>
                    <span>$ 109,507</span>
                    <span>1.5046</span>
                    <span>Apple Inc.</span>
                    <span>$ 10,759</span>
                    <span>5.8125</span>
                  </TextLoop>
                </b>
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight text-center lg:text-left">
                No more digging
            </h3>
              <p className="mt-8 text-xl font-light leading-relaxed text-center lg:text-left">
                Forget about going through pages and pages of dense financial reports to look for the numbers you need. Marketsheets lets you focus on what really matters: understanding the company’s business and strategy.
            </p>
            </div>
          }
          secondarySlot={<img className="mx-auto w-2/3" src={Paperwork} />}
        />
      </section>

      <section id="metric-search" className="pt-5 md:pt-5">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl mt-16 lg:text-5xl font-semibold">Explore metrics</h2>
        </div>
        <div className="py-5 pb-10 px-4 lg:text-lg text-gray-500 font-medium">
          <div className="text-center">Enrich your spreadsheets with over 10 million data points</div>
        </div>
        <div className="container h-full mx-auto px-8">
          <SearchMetric />
        </div>
      </section>

      <section id="pricing" className="pt-32 mb-48">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Pricing</h2>
          <div className="flex flex-col lg:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                {/* <img className="mx-auto mt-10 h-24" src={Free} alt="Workflow" /> */}
                <div className="text-4xl mt-8 text-center text-gray-800 font-bold">
                  Free
                </div>
                <div className="text-base mt-1 text-center text-gray-400 font-bold">
                  Try out Marketsheets
                </div>
                <div className="mt-5 h-40">
                  <div className="text-center mx-auto">
                    <span className="inline-block align-top pt-5 font-bold text-primary text-2xl">
                      $
                  </span>
                    <span className="inline-block font-bold text-primary text-6xl">
                      0
                  </span>
                  </div>
                  <div className="text-sm text-center text-gray-400">
                    per month
                </div>
                </div>
                <div className="flex text-left justify-center h-32 ml-6 lg:ml-12 xl:pr-10 ">
                  <ul className="list-disc text-sm">
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Data limited to 30 stocks
                      </span>
                    </li>
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Up to 100 function calls / day
                      </span>
                    </li>
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Access to 450 metrics
                      </span>
                    </li>
                  </ul>
                </div>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                {/* <img className="mx-auto mt-10 h-24" src={Standard} alt="Workflow" /> */}
                <div className="text-4xl mt-8 text-center text-gray-800 font-bold">
                  Standard
                </div>
                <div className="text-base mt-1 text-center text-gray-400 font-bold">
                  Everything you need
                </div>
                <div className="mt-5 h-40">
                  <div className="text-center mx-auto">
                    <span className="inline-block align-top pt-5 font-bold text-primary text-2xl">
                      $
                    </span>
                    <span className="inline-block font-bold text-primary text-6xl">
                      9
                    </span>
                    <span className="text-sm inline-block text-primary align-top pt-5">99</span>
                  </div>
                  <div className="text-sm text-center text-gray-400">
                    per month
                  </div>
                </div>
                <div className="flex text-left justify-center h-32 ml-4 lg:ml-12 xl:pr-10 ">
                  <ul className="list-disc text-sm">
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Access to data for 20 Stock Exchanges
                      </span>
                    </li>
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Up to 10,000 function calls / day
                      </span>
                    </li>
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Access to 1200 metrics
                      </span>
                    </li>
                  </ul>
                </div>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                {/* <img className="mx-auto mt-10 px-0 h-24" src={Enterprise} alt="Workflow" /> */}
                <div className="text-4xl mt-8 text-center text-gray-800 font-bold">
                  Enterprise
                </div>
                <div className="text-base mt-1 text-center text-gray-400 font-bold">
                  For your business
                </div>
                <div className="mt-5 pt-5 h-40">
                  <div className="text-center mx-auto">
                    <span className="inline-block font-bold text-primary text-3xl">
                      Customized
                    </span>
                  </div>
                  <div className="text-sm text-center mt-5 text-gray-400">
                    per month
                  </div>
                </div>
                <div className="flex text-left justify-center h-32 md:ml-10 xl:pr-10 ">
                  <ul className="list-disc text-sm">
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Volume discounts
                      </span>
                    </li>
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Custom metric feeds
                      </span>
                    </li>
                    <li className="text-primary text-base">
                      <span className="text-gray-700 text-sm">
                        Premium Support
                      </span>
                    </li>
                  </ul>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <MailingList />

      {/* <section id="learnMore">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">Learn More</LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {learnMoreData.map(content => (
              <div key={content.title} className="flex-1 px-3">
                <ItemCard content={content} />
              </div>
            ))}
          </div>
        </div>
      </section> */}

  </Layout>
  );
}

export default Index;